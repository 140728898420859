import { Button } from "components/Button/Button";
import useFullPageLoader from "hooks/useFullPageLoader";
import useMuiButtonLoader from "hooks/useMuiButtonLoader";
import React, { useState } from "react";
import { getCources } from "services/course/courseController";
import toast from "react-hot-toast";
import ReactDatePicker from "react-datepicker";
import NotFound from "components/not-found/NotFound";
import Pagination from "react-js-pagination";
import LocationIcon from "assets/images/684908.png";
import { GoogleMap, LoadScript, InfoWindow } from '@react-google-maps/api';

import { Table, Modal, Button as BootstrapButton } from "react-bootstrap";
//import { Table } from "react-bootstrap";
import {
  evaluatorBillingExcelDownload,
} from "services/evaluation/evaluationController";
import { livelectureDetailsHistory } from "services/lecture/lectureController";
import { dateFormat, simpleDateFormat, simpleDateFormatForSearch } from "utils";
import useDebounce from "hooks/useDebounce";
import { TableSearch } from "components/Form/TableSearch";
import ReactSelect from "react-select";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
const LiveLectureDetailsHistory = () => {
  // const [allEvaluators, setAllEvaluators] = useState([]);
  // const [selectedEvaluator, setSelectedEvaluator] = useState("");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [allCourses, setAllCourses] = useState([]);
  const [total, setTotal] = useState(0);
  const [record, setRecord] = useState([]);
  const [price, setPrice] = useState(0);
  const [flag, setFlag] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState({});
  const debounceValue = useDebounce(search, 300);
  const [searchResults, setSearchResults] = useState([]);

  const [submitButtonElement, setSubmitLoading] = useMuiButtonLoader();
  const [submitButtonElement2, setSubmitLoading2] = useMuiButtonLoader();
  const [openModal, setOpenModal] = useState(false);

  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [showMapPopup, setShowMapPopup] = useState(false);

  const toggleMapPopup = (latitude, longitude) => {
    setSelectedLocation({ latitude, longitude });
    setShowMapPopup(!showMapPopup);
  };
  // const toggleMapPopup = (latitude, longitude) => {
  //   setSelectedLocation({ latitude, longitude });
  //   setShowMapPopup(true);
  // };

  const closeMapPopup = () => {
    setShowMapPopup(false);
  };
  const [originalOptions, setOriginalOptions] = useState([]);
const [searchOptions, setSearchOptions] = useState([]);
const submitArtifactHandler = () => {
  // Placeholder function logic
  console.log("Artifact submitted!");
};
const showLocationDetails = (latitude, longitude) => {
  setSelectedLocation({ latitude, longitude });
  setOpenModal(true);
};
const customMarkerIcon = new L.Icon({
  iconUrl: LocationIcon,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
const [selectedLocation, setSelectedLocation] = useState({ latitude: 0, longitude: 0 });

const getLiveLectureDetailsHistory = async (
  page = activePage,
  search = debounceValue,
  type
) => {
  try {
    showLoader();
    let otherSearch = {};
    for (let [key, value] of Object.entries(searchData)) {
      const bool = key.split("-")?.[1] === "date";
      if (bool) {
        Object.assign(otherSearch, {
          [key.split("-")?.[0]]: simpleDateFormatForSearch(value),
        });
      } else {
        Object.assign(otherSearch, { [key]: value });
      }
    }

    const finalData = {
      isDownload: 0,
      startDate: simpleDateFormat(startDate),
      endDate: simpleDateFormat(endDate),
      courseId: selectedCourse?.value,
      page,
      search,
      ...otherSearch,
    };

    if (type === "search") {
      setSubmitLoading(true);
    }

    const res = await livelectureDetailsHistory(finalData);

    hideLoader();
    if (res.code === 200) {
      const { rows, count, courses } = res.data.playlivelecturedetailhistory;

      // setRecord(res.data.lectureDetailsHistory || []);
      setRecord(rows || []);
      
      // Check if res.data.courses is an array before mapping
      const coursesList = Array.isArray(courses)
        ? courses.map((data) => ({
            label: data.CourseTitle,
            value: data.CourseID,
          }))
        : [];
      
      // setSearchResults(res.data.lectureDetailsHistory || []);
      setSearchResults(rows || []);
      setAllCourses(coursesList);
      setTotal(count || 0);
      setSubmitLoading(false);
      setFlag(true);
    } else {
      toast.error(res.message);
      setFlag(false);
      setSubmitLoading(false);
    }
  } catch (err) {
    toast.error(err?.response?.data?.message || err.message);
    setSubmitLoading(false);
  }
};

 
  
  const courseSelection = (data) => {
   setSelectedCourse(data);
 };
  React.useLayoutEffect(() => {
  //  const getAllCourses = async () => {
  //    showLoader();
  //    try {
  //      const res = await getCourses();

  //      if (res.code === 200) {
  //        setAllCourses(
  //          res.data.courses.rows.map(({ id, name, startDate, endDate }) => {
  //            return { label: name, value: id, startDate, endDate };
  //          }) || []
  //        );
  //        hideLoader();
  //      } else {
  //        toast.error(res.message);
  //      }
  //    } catch (err) {
  //      toast.error(err?.response?.data?.message || err.message);
  //    }
  //  };
  const getAllCourses = async () => {
    showLoader();
    try {
      const res = await getCources();
  
      if (res.code === 200) {
        const courses = res.data.courses.map(({ CourseID, CourseTitle, startDate, endDate }) => {
          return { label: CourseTitle, value: CourseID, startDate, endDate };
        }) || [];
  
        // Update both original and search options
        setOriginalOptions(courses);
        setSearchOptions(courses);
  
        hideLoader();
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    }
  };
  
  getAllCourses();
   // calls all the master functions
   //getAllTestTypes();
   // eslint-disable-next-line
 }, []);

  //   search courses by student email
  const searchHandler = async (e) => {
    e.preventDefault();
    if (!startDate && !endDate && !selectedCourse) {
      toast.error("Please Select Start Date and End Date");
      // } else if (!selectedEvaluator) {
      // toast.error("Please Select Evaluator");
    } else {
      getLiveLectureDetailsHistory(activePage, debounceValue, "search");
    }
  };

  // download excel file
  // const downloadExcelHandler = async () => {
  //   if (!startDate && !endDate) {
  //     toast.error("Please Select Start Date and End Date");
  //     // } else if (!selectedEvaluator) {
  //     // toast.error("Please Select Evaluator");
  //   } else {
  //     try {
  //       setSubmitLoading2(true);
  //       const finalData = {
  //         isDownload: 1,
  //         startDate: simpleDateFormat(startDate),
  //         endDate: simpleDateFormat(endDate),
  //         // evaluatorId: selectedEvaluator?.value,
  //         search,
  //         ...searchData,
  //       };
  //       const res = await evaluatorBillingExcelDownload(finalData);
  //       window.open(res);
  //       setSubmitLoading2(false);
  //     } catch (err) {
  //       toast.error(err?.response?.data?.message || err.message);
  //       setSubmitLoading2(false);
  //     }
  //   }
  // };

  // --------------- getting all required masters data ----------------//
  // React.useLayoutEffect(() => {
  //   const getAllEvaluators = async () => {
  //     showLoader();
  //     try {
  //       showLoader();
  //       const res = await evaluatorListForDashboard({
  //         type: 0,
  //       });

  //       hideLoader();
  //       if (res.code === 200) {
  //         const { evaluators } = res.data;
  //         setAllEvaluators(
  //           evaluators.map((data) => {
  //             return {
  //               label: `${data.FirstName} ${data.LastName}`,
  //               value: data.UserID,
  //             };
  //           }) || []
  //         );
  //       } else {
  //         toast.error(res.message);
  //       }
  //     } catch (err) {
  //       toast.error(err?.response?.data?.message || err.message);
  //     }
  //   };
  //   // calls all the master functions
  //   getAllEvaluators();
  //   // eslint-disable-next-line
  // }, []);

  React.useEffect(() => {
    if (
      startDate &&
      endDate 
      // && selectedEvaluator?.value
    ) {
      getLiveLectureDetailsHistory();
    }
    // eslint-disable-next-line
  }, [activePage, debounceValue, searchData]);

  return (
    <React.Fragment>
      <title>Shubhra Viraj | LiveLecture View Location Report</title>
      <div className="">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="search_wrap">
              <div className="SearchBox"></div>
              <h4 className="Title">LiveLecture View Location Report</h4>
            </div>
            <div className=" mt-3">
              <form onSubmit={searchHandler}>
                <div className="row w-100">
                  <div className={`col-form-label py-0 col-sm-3`}>
                    <ReactDatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className={`form-control`}
                      maxDate={new Date()}
                      showIcon
                      dateFormat="dd-MMM-yyyy"
                      placeholderText="Select Start Date"
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      onChangeRaw={(e) => e.preventDefault()}
                    />
                  </div>

                  <div className={`col-form-label py-0 col-sm-3`}>
                    <ReactDatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      className={`form-control`}
                      minDate={startDate}
                      maxDate={new Date()}
                      showIcon
                      dateFormat="dd-MMM-yyyy"
                      placeholderText="Select End Date"
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      onChangeRaw={(e) => e.preventDefault()}
                    />
                  </div>
                  <div className={`col-form-label py-0 col-sm-4`}>
                  <ReactSelect
  className={`w-100 react-select`}
  placeholder="Select Course"
  options={debounceValue ? searchOptions : originalOptions}
  value={selectedCourse}
  onChange={courseSelection}
  isSearchable={true}
/>
                </div>

                  {/* <div className="col-sm-4 mui-auto-complete">
                    <ReactSelect
                      className={`w-100 react-select`}
                      placeholder="Select Evaluator"
                      options={allEvaluators}
                      value={selectedEvaluator}
                      onChange={(data) => setSelectedEvaluator(data)}
                      isSearchable={true}
                    />
                  </div> */}

                  <div className="col-sm-1 mx-4">
                    <Button type="submit" {...submitButtonElement}>
                      Search
                    </Button>
                  </div>
                </div>
              </form>
            </div>
            {loader}
            <hr className="border border-danger" />
          </div>
          {flag && (
            <div className="Small-Wrapper my-3">
              <div className="search_wrap">
                <div className="SearchBox">
                  <div className="div_wrap">
                    {/* <input
                      type="text"
                      placeholder="Search"
                      className="form-control"
                      name="search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
          /> */}
                  </div>
                  <div className="div_no_wrap mx-1 row"></div>

                  {/* <Button
                    className="mx-2"
                    onClick={downloadExcelHandler}
                    {...submitButtonElement2}
                  >
                    Export To Excel
                  </Button> */}
                </div>
                <h4 className="Title">LiveLecture View Location Dashboard</h4>
              </div>
              {/* <h6 className="Title">Total ₹ {price}</h6> */}
              <div className="TableList overflow-auto table-responsive">
                {loader}
                <Table bordered className="table">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Student Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>Course Title</th>
                      <th>Lecture Title</th>
                      <th>IP</th>
                      <th>Platform</th>
                      <th>Location</th>
                      <th>Viewed Date</th>
                      
                    </tr>
                    <tr>
                      <TableSearch
                        searchData={searchData}
                        setSearchData={setSearchData}
                        items={[
                          "",
                          "StudentName",
                          "EmailID",
                          "Mobile",
                          "CourseTitle",
                          "LectureTitle",
                          "ip",
                          "platform",
                          "Location",
                          "ViewedDate",
                        ]}
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {record && record.length > 0 ? (
                      record.map((data, i) => (
                        <tr key={i}>
                          <td>{++i + (activePage - 1) * 10}</td>
                          <td>{data.StudentName}</td>
                          <td>{data.EmailID}</td>
                          <td>{data.Mobile}</td>
                          <td>{data.CourseTitle}</td>
                          <td>{data.LectureTitle}</td>
                          <td>{data.ip}</td>
                          <td>{data.platform.toLowerCase() === 'chrome' ? 'website' : data.platform}</td>
                          <td>
 <span
  style={{ cursor: 'pointer', color: 'blue' }}
  onClick={() => toggleMapPopup(data.latitude, data.longitude)}
>
                  <i className="fas fa-map-marker-alt" style={{ marginRight: '20px' }} />
                  <img src={LocationIcon} alt="icon" style={{ width: '30px', height: '30px', marginRight: '20px' }}/>
                 
                </span>
              </td>
                          {/* <td>{data.ViewedDate}</td> */}

                          <td>
                            {data.ViewedDate
                              ? dateFormat(data.ViewedDate)
                              : "N/A"}
                          </td>
                          {/* <td>{data.EGrade}</td>
                          <td> ₹ {data.price || 0}</td> */}
                        </tr>
                      ))
                    ) : (
                      <NotFound cols={12} />
                    )}
                  </tbody>
                </Table>
                {showMapPopup && (
        <Modal show={showMapPopup} onHide={closeMapPopup} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Location Map</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MapContainer
              center={[selectedLocation.latitude, selectedLocation.longitude]}
              zoom={13}
              style={{ height: '400px', width: '100%' }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={[selectedLocation.latitude, selectedLocation.longitude]} icon={customMarkerIcon}>
                <Popup>
                  Latitude: {selectedLocation.latitude}, Longitude: {selectedLocation.longitude}
                </Popup>
              </Marker>
            </MapContainer>
          </Modal.Body>
        </Modal>
      )}
              </div>
              {total > 10 ? (
                <div className="Pagination">
                  <Pagination
                    activePage={activePage}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    itemsCountPerPage={10}
                    totalItemsCount={total}
                    pageRangeDisplayed={5}
                    onChange={(page) => setActivePage(page)}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default LiveLectureDetailsHistory;

