import ReactDatePicker from "react-datepicker";
import { Input } from ".";

export const TableSearch = ({ searchData, setSearchData, items = [] }) => {
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setSearchData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  return (
    <>
      {items.map((data, i) => {
        return data ? (
          <th key={i} style={{ padding: "3px" }}>
            {data.split("-")?.[1] === "date" ? (
              <ReactDatePicker
                selected={searchData[data]}
                onChange={(date) =>
                  setSearchData({ ...searchData, [data]: date })
                }
                className={`form-control multi_search_date`}
                isClearable
                // showIcon
                dateFormat="dd-MMM-yyyy"
                placeholderText="dd-MMM-yyyy"
                showMonthDropdown
                useShortMonthInDropdown
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                maxDate={new Date()}
              />
            ) : (
              <Input
                onChange={onChangeHandler}
                placeholder={"Search"}
                className={"form-control"}
                name={data}
                value={searchData[data] || ""}
                style={{ padding: "3px 8px" }}
              />
            )}
          </th>
        ) : (
          <th key={i}></th>
        );
      })}
    </>
  );
};
