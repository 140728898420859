import { decryptData, encryptData } from "config/config";
import { instance as axios, setMultiPartHeader } from "config/axiosInstance";
import { isLoggedIn } from "utils";

export const getSiteSetting = async (data) => {
    return decryptData(
      await axios.get(
        `/site-setting-list?reqData=${encryptData({
          ...data,
          access_token: isLoggedIn(),
        })}`
      )
    );
  };

  export const updateSiteSettingStatus = async (data) => {
    return decryptData(
      await axios.get(
        `/site-setting-status?reqData=${encryptData({
          ...data,
          access_token: isLoggedIn(),
        })}`
      )
    );
  };

  export const updateSiteSettingValue = async (data) => {
    return decryptData(
      await axios.post(`/site-setting-edit`, {
        reqData: encryptData({
          ...data,
          access_token: isLoggedIn(),
        }),
      })
    );
  };