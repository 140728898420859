import React, { useState } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { instance as axios } from "config/axiosInstance";
import Logo from "assets/images/fullLogo.png";

export default function UserResetPassword() {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [errors, setError] = useState({ newPassword: "", confirmPassword: "" });

  //*************Handel Validation**************** */
  const handleValidation = () => {
    let errors = {};
    // let regex = "^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$";
    let formIsValid = true;
    if (!newPassword) {
      formIsValid = false;
      errors["newPassword"] = "Please enter new password";
    }
    if (newPassword && newPassword.length < 8) {
      formIsValid = false;
      errors["newPassword"] =
        "Password must be greater than or equal to 8 character";
    }

    if (newPassword && !/^(?=.*[a-z]).*$/.test(newPassword)) {
      formIsValid = false;
      errors["newPassword"] =
        "Password must have at least one Lowercase Character";
    }

    if (newPassword && !/^(?=.*[A-Z]).*$/.test(newPassword)) {
      formIsValid = false;
      errors["newPassword"] =
        "Password must have at least one Uppercase Character";
    }

    if (newPassword && !/^(?=.*[0-9]).*$/.test(newPassword)) {
      formIsValid = false;
      errors["newPassword"] = "Password must contain at least one Digit";
    }

    if (!confirmPassword) {
      formIsValid = false;
      errors["confirmPassword"] = "Please enter confirm password";
    }

    if (confirmPassword !== newPassword) {
      formIsValid = false;
      errors["confirmPassword"] =
        "Confirm password must be equal to new password";
    }
    setError(errors);
    return formIsValid;
  };

  //********Update Password********** */
  const UpdatePassword = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      const { data } = await axios.post(`/user/forgot-password`, {
        token: token,
        confirmPassword,
        newPassword,
      });
      if (data.status === 200) {
        setNewPassword("");
        setConfirmPassword("");
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    }
    //
  };

  //*********Password toggle hide show******** */
  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const toggleConfirmPassword = (e) => {
    e.preventDefault();
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };
  return (
    <React.Fragment>
      <title>Mednovate | Reset Password</title>
      <div className="LoginArea">
        <form method="POST">
          <div className="LoginBox">
            <figure>
              <img src={Logo} alt="Logo" className="my-2" loading="lazy" />
            </figure>
            <h5>Reset Password</h5>

            <div className="form-group">
              <label>New Password</label>
              <input
                type={passwordType}
                placeholder="New Password"
                className="form-control"
                name="mewPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />

              <span
                className="passwordHideShow"
                onClick={(e) => {
                  togglePassword(e);
                }}
              >
                {passwordType === "password" ? (
                  <i className="fa fa-eye-slash"></i>
                ) : (
                  <i className="fa fa-eye"></i>
                )}
              </span>
              <span className="Icon">
                <i className="fa fa-key"></i>
              </span>
              <span style={{ color: "red" }}>{errors.newPassword}</span>
            </div>

            <div className="form-group">
              <label>Confirm Password</label>
              <input
                type={confirmPasswordType}
                placeholder="Confirm Password"
                className="form-control"
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <span
                className="passwordHideShow"
                onClick={(e) => {
                  toggleConfirmPassword(e);
                }}
              >
                {confirmPasswordType === "password" ? (
                  <i className="fa fa-eye-slash"></i>
                ) : (
                  <i className="fa fa-eye"></i>
                )}
              </span>
              <span className="Icon">
                <i className="fa fa-key"></i>
              </span>
              <span style={{ color: "red" }}>{errors.confirmPassword}</span>
            </div>
            <div className="button_wrapper">
              <button onClick={UpdatePassword}>
                Update Password <i className="fa fa-sign-in"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}
