import React from "react";

const NotFound = ({cols=9}) => {
  return (
    <tr>
      <td colSpan={cols}>
        <div className="text-center mt-2">
          <h5>Entries Not Found</h5>
        </div>
      </td>
    </tr>
  );
};

export default NotFound;
