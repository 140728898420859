import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import useButtonLoader from "hooks/useButtonLoader";
import Logo from "assets/images/fullLogo.png";
import { resetPassword } from "services/auth";

const ResetPassword = () => {
  const history = useNavigate();
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setError] = useState({ newPassword: "", confirmPassword: "" });
  const [submitButtonElement, setSubmitLoading] = useButtonLoader(
    "Update Password",
    "Please wait..."
  );

  //****************Handel Validation************************ */
  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!newPassword) {
      formIsValid = false;
      errors["newPassword"] = "Please enter new password";
    }
    if (newPassword.length < 8) {
      formIsValid = false;
      errors["newPassword"] =
        "Password must be greater than or equal to 8 character";
    }
    if (!confirmPassword) {
      formIsValid = false;
      setConfirmPassword("");
      errors["confirmPassword"] = "Please enter confirm password";
    }

    if (confirmPassword !== newPassword) {
      formIsValid = false;
      setConfirmPassword("");
      errors["confirmPassword"] =
        "Confirm password must be equal to new password";
    }
    setError(errors);
    return formIsValid;
  };

  //********************Update Password******************************* */
  const UpdatePassword = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      setSubmitLoading(true);
      const res = await resetPassword({
        password: newPassword,
        confirmPassword,
        key: token,
      });
      setSubmitLoading(false);
      if (res.code === 200) {
        toast.success(res.message);
        history("/");
      } else {
        toast.error(res.message);
      }
    }
  };
  return (
    <React.Fragment>
      <title>Shubhra Viraj | Reset Password</title>
      <div className="LoginArea">
        <form method="POST">
          <div className="LoginBox">
            <figure>
              <img src={Logo} alt="Logo" className="my-2" loading="lazy" />
            </figure>
            <h5>Reset Password</h5>
            {/* <h6>
              To keep connected wit us please login<br></br> wit your personal
              info.
            </h6> */}

            <div className="form-group">
              <label>New Password</label>
              <input
                type="password"
                placeholder="New Password"
                className="form-control"
                name="mewPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <span className="Icon">
                <i className="fa fa-key"></i>
              </span>
              <span style={{ color: "red" }}>{errors.newPassword}</span>
            </div>

            <div className="form-group">
              <label>Confirm Password</label>
              <input
                type="password"
                placeholder="Confirm Password"
                className="form-control"
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <span className="Icon">
                <i className="fa fa-key"></i>
              </span>
              <span style={{ color: "red" }}>{errors.confirmPassword}</span>
            </div>
            <div className="button_wrap">
              <button onClick={UpdatePassword} ref={submitButtonElement}>
                Update Password <i className="fa fa-sign-in"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
