import { decryptData, encryptData } from "config/config";
import { instance as axios, setMultiPartHeader } from "config/axiosInstance";
import { isLoggedIn } from "utils";

export const getAdvertisements = async (data) => {
  return decryptData(
    await axios.get(
      `/advertisement/list?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const addAdvertisement = async (data) => {
  return decryptData(
    await axios.post(`/advertisement/create`, data, setMultiPartHeader())
  );
};

export const deleteAdvertisement = async (data) => {
  return decryptData(
    await axios.get(
      `/advertisement/delete?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
