import { decryptData, encryptData } from "config/config";
import { instance as axios } from "config/axiosInstance";
import { isLoggedIn } from "utils";

export const login = async (data) => {
  return decryptData(
    await axios.post(`/login`, { reqData: encryptData(data) })
  );
};

export const forgotPassword = async (data) => {
  return decryptData(
    await axios.post(`/forgot-password`, { reqData: encryptData(data) })
  );
};

export const changePassword = async (data) => {
  return decryptData(
    await axios.post(`/change-password`, { reqData: encryptData({ ...data,
      access_token: isLoggedIn()}) })
  );
};

export const resetPassword = async (data) => {
  return decryptData(
    await axios.post(`/reset-password`, { reqData: encryptData(data) })
  );
};
