import React from "react";
import { Alert } from "react-bootstrap";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div
          className={this.props.fullMenu ? "WrapperArea" : "WrapperArea-Small"}
        >
          <div className="dashboard_home">
            <div className="WrapperBox">
              <div className="Small-Wrapper">
                <Alert show={true} variant="success">
                  <Alert.Heading>
                    Something went wrong: Please Refresh
                  </Alert.Heading>
                  <details style={{ whiteSpace: "pre-wrap" }}>
                    {this.state.error && this.state.error.toString()}
                    <br />
                    {this.state.errorInfo.componentStack}
                  </details>
                  {/* <hr />
        <div className="d-flex justify-content-center">
          <Button onClick={resetErrorBoundary}>Try Again</Button>
        </div> */}
                </Alert>
              </div>
            </div>
          </div>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
