import React, { useState } from "react";
import Loader from "components/loader/Loader";

const useFullPageLoader = (type) => {
  const [loading, setLoading] = useState(false);

  return [
    loading ? <Loader status={loading} type={type} /> : null,
    () => setLoading(true), //Show loader
    () => setLoading(false), //Hide Loader
  ];
};

export default useFullPageLoader;
