import React, { createContext, useContext, useState } from "react";

const AdminContext = createContext();

export const AdminContextProvider = ({ children }) => {
  const [roles, setRoles] = useState([]);
  return (
    <AdminContext.Provider value={{ roles, setRoles }}>
      {children}
    </AdminContext.Provider>
  );
};

export const useAdminAuth = () => {
  return useContext(AdminContext);
};
