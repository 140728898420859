import "./App.css";
import Routing from "./routes";
import { Toaster } from "react-hot-toast";
import { HashRouter, BrowserRouter } from "react-router-dom";
import { AxiosInterceptor } from "config/axiosInstance";
import { disableReactDevTools, normalDecryptData } from "config/config";
import { CookiesProvider } from "react-cookie";
import { useAdminAuth } from "context/context";
import React from "react";

const App = () => {
  // disable react dev tools in production mode
  if (process.env.NODE_ENV === "production") disableReactDevTools();
  const { setRoles } = useAdminAuth();

  React.useEffect(() => {
    const roles = localStorage.getItem(process.env.REACT_APP_AUTH);
    if (roles) {
      let rolesData = roles ? JSON.parse(normalDecryptData(roles)) : [];
      setRoles(rolesData || []);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      <BrowserRouter basename="/admin">
        {/* <HashRouter> */}
        <CookiesProvider>
          <AxiosInterceptor>
            <Routing />
            <Toaster position="top-right" />
          </AxiosInterceptor>
        </CookiesProvider>
        {/* </HashRouter> */}
      </BrowserRouter>
    </div>
  );
};

export default App;
