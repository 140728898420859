import { useState } from "react";

const useDropdownToggler = () => {
  const [style, setStyle] = useState(localStorage.getItem("toggle") || "");

  const ddToggler = (value) => {
    setStyle(value);
    localStorage.setItem("toggle", value);
  };

  return [style, ddToggler];
};

export default useDropdownToggler;
