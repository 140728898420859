import React from "react";

export const Input = ({
  value,
  onChange,
  className,
  label,
  error,
  required,
  loading,
  ...rest
}) => {
  return (
    <>
      {label && <label className="my-1">{label}</label>}
      {required && <span className="text-danger"> *</span>}
      <input
        value={value}
        onChange={onChange}
        className={`${className} ${!value && error && "is-invalid"}`}
        {...rest}
        loading={loading ? "" : ""}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
};

export const Select = ({
  label,
  onChange,
  value,
  error,
  className,
  children,
  required,
  loading,
  ...rest
}) => {
  return (
    <>
      {label && <label className="my-1">{label}</label>}
      {required && <span className="text-danger"> *</span>}
      <select
        onChange={onChange}
        value={value}
        className={`${className} ${!value && error && "is-invalid"}`}
        {...rest}
        loading={loading ? "" : ""}
      >
        {children}
      </select>
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
};

export const TextArea = ({
  value,
  onChange,
  label,
  className,
  error,
  required,
  loading,
  ...rest
}) => {
  return (
    <>
      {label && <label className="my-1">{label}</label>}
      {required && <span className="text-danger"> *</span>}
      <textarea
        value={value}
        onChange={onChange}
        className={`${className} ${!value && error && "is-invalid"}`}
        {...rest}
        loading={loading ? "" : ""}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
};
